(() => {  

  let opendPopover = undefined;


  const addWordBeforeSupToSup = (sups) => {
    sups.forEach(supElement => {
      if (supElement.closest('.refpopover__wrapper')) {
        return;
      }
  
      const parentElement = supElement.parentNode;
      if (!parentElement) {
        return;
      }
  
      let node = supElement.previousSibling;
  
      while (node) {
        if (node.nodeType === Node.TEXT_NODE) {
          const text = node.textContent.trimEnd();
          const words = text.split(' ');
          if (words.length > 0) {
            const lastWord = words.pop();
            node.textContent = words.join(' ');
            wrapLastWordAndSup(parentElement, lastWord, supElement, node.nextSibling);
            return;
          }
        } else if (node.nodeType === Node.ELEMENT_NODE) {
          const textNodesInElement = getTextNodesInReverse(node);
          for (const textNode of textNodesInElement) {
            const text = textNode.textContent.trimEnd();
            const words = text.split(' ');
            if (words.length > 0) {
              const lastWord = words.pop();
              textNode.textContent = words.join(' ');
              wrapLastWordAndSup(parentElement, lastWord, supElement, supElement);
              return;
            }
          }
        }
        node = node.previousSibling;
      }
    });
  };
  
  const getTextNodesInReverse = (element) => {
    const textNodes = [];
    const stack = [element];
    while (stack.length > 0) {
      const current = stack.pop();
      if (current.nodeType === Node.TEXT_NODE) {
        textNodes.push(current);
      } else if (current.nodeType === Node.ELEMENT_NODE) {
        for (let i = current.childNodes.length - 1; i >= 0; i--) {
          stack.push(current.childNodes[i]);
        }
      }
    }
    return textNodes.reverse();
  }
  
  const wrapLastWordAndSup = (parentElement, lastWord, supElement, insertBeforeNode) => {
    const wrapperDiv = document.createElement('div');
    wrapperDiv.className = "refpopover__wrapper";
    wrapperDiv.style.display = 'inline-block';
    wrapperDiv.innerHTML = `&nbsp;${lastWord}${supElement.outerHTML}`;
    parentElement.insertBefore(wrapperDiv, insertBeforeNode);
    supElement.remove();
  }

  const addButtonClicks = (buttons) => {
    const onClosePopupClick = (event) => {
      const rect = opendPopover.getBoundingClientRect();
      const isInDialog = (
        event.clientX >= rect.left &&
        event.clientX <= rect.right &&
        event.clientY >= rect.top &&
        event.clientY <= rect.bottom
      );

      if (!isInDialog) {
        opendPopover.close();
        opendPopover = undefined;
      }
    }

    const onButtonClick = (event) => {
      event.preventDefault();
      event.stopImmediatePropagation();

      if ( opendPopover ) {
        opendPopover.close();
        opendPopover = undefined;
      }
      const popoverId = event.target.getAttribute('popovertarget');
      const currentPopover = document.getElementById(popoverId);
      const rect = event.target.parentNode.getBoundingClientRect();
      const scrollLeft = document.documentElement.scrollLeft;
      const scrollTop = document.documentElement.scrollTop;
      if(!currentPopover.contains(currentPopover.querySelector('button'))){
        const closeButton = document.createElement('button');
        closeButton.type ='button';
        closeButton.setAttribute('popovertarget', currentPopover.id);
        closeButton.setAttribute('popovertargetaction', 'hide');

        const closeLabel = document.createElement('span');
        closeLabel.classList.add('sr-only');
        closeLabel.textContent = 'schließen';

        closeButton.append(closeLabel);

        currentPopover.prepend(closeButton);
        currentPopover.addEventListener('click', onClosePopupClick);

        closeButton.addEventListener('click', () => {
          opendPopover.close();
        });
      }
      opendPopover = currentPopover;
      currentPopover.style.left = rect.left + scrollLeft + "px";
      currentPopover.style.top = rect.top + scrollTop + "px";
      opendPopover.showModal();
      const popoverRect = opendPopover.getBoundingClientRect();
      currentPopover.style.left = rect.left + scrollLeft - (popoverRect.width / 2) + "px";
      currentPopover.style.top = rect.top + scrollTop  - (popoverRect.height + 12) + "px";
    };

    
    buttons.forEach((btn) => {
      btn.addEventListener('click', onButtonClick);
    });
  }

  const getMoreSubs = (elem) => {
    const sibs = [];
    while (elem = elem.nextSibling) {
        if (elem.nodeType === 3) break; // text node
        if (elem.nodeName.toUpperCase() === 'SUP') {
          sibs.push(elem);
        }
    }
    return sibs;
  }

  const updateReferences = (sups) => {
    sups.forEach((sup) => {
      if(!sup.textContent.includes("ref") || sup.className === 'refsup') return;

      const refIds = [];
      const refTargets = [];
      const orders = [];
      
      const myPopover = document.createElement('dialog');
      const moreSubs = getMoreSubs(sup);
  
      moreSubs.unshift(sup);
    
      moreSubs.forEach((moreSup, index) => {
        const refId = moreSup.textContent;
        const refTarget = document.getElementById(refId);
        const order = refTarget.dataset.order;
  
        refIds.push(refId);
        refTargets.push(refTarget);
        orders.push(order);
  
        const clonedNode = refTarget.cloneNode(true);
        clonedNode.removeAttribute('id');
        clonedNode.removeAttribute('class');
        clonedNode.removeAttribute('hidden');
  
        myPopover.append(clonedNode);
  
        if(index > 0){
          moreSup.remove();
        }
      });
  
      const popoverId = refIds.join("") + '_';
  
      myPopover.setAttribute('popover', 'auto');
      myPopover.setAttribute('id', popoverId);
      myPopover.className ='refpopover';
      document.querySelector('main').append(myPopover);
  
      sup.classList.add('refsup');
      sup.innerHTML = '<button type="button" class="trigger-refpopover" popovertarget="'+popoverId+'">'+orders+'</button>';
    });
  };

  /** add a global function for initialize contents taht will be dynamically loaded and needs to update ref sups
   * @param HtmlElement target  the trrget html elemnt where the sup tags should be reworked
   */
  window.initRefPopover = (target) => {
    const sups = target.querySelectorAll("sup");
    updateReferences(sups)
    addWordBeforeSupToSup(sups);
    const buttons = target.querySelectorAll(".trigger-refpopover");
    addButtonClicks(buttons);
  };

  const sups = document.querySelectorAll("sup");
  updateReferences(sups);
  addWordBeforeSupToSup(sups);
  const buttons = document.querySelectorAll(".trigger-refpopover");
  addButtonClicks(buttons);

})();