import { Utils } from "../../helpers/utils";

window.searchPage = window.searchPage || 0;
window.VIDEOGALLERY_PAGE = window.VIDEOGALLERY_PAGE || 0;

$(function () {
  $("body").on("click", ".js-next-tab", function (e) {
    var this_ = $(this);
    e.preventDefault();
    goToNextTab(this_.closest(".tab_container")[0]);
  });
  $("body").on("click", ".js-prev-tab", function (e) {
    var this_ = $(this);
    e.preventDefault();
    goToPrevTab(this_.closest(".tab_container")[0]);
  });
  function goToNextTab(slide, carousel_) {
    var slide_ = slide,
      currentSlide_ = slide_.querySelector(".tab_menu li.active"),
      currentPos_ = currentSlide_.getAttribute("data-pos"),
      nextPos_ = parseInt(currentPos_) + 1,
      nextPosExists_ = slide_.querySelector(
        '.tab_menu li[data-pos="' + nextPos_ + '"]'
      ),
      nextSlide_;

    if (nextPosExists_) {
      nextSlide_ = nextPosExists_;
    } else if (carousel_) {
      nextSlide_ = slide_.querySelector('.tab_menu li[data-pos="0"]');
      nextPos_ = 0;
    }
    if (nextPosExists_ || carousel_) {
      currentSlide_.classList.remove("active");
      nextSlide_.classList.add("active");
      if ($(slide_).hasClass("tab_container--symptomchecker")) {
        $(slide_).find(".tab_menu li").removeClass("filled");
        $(slide_).find(".tab_menu .active").prevAll().addClass("filled");
      }

      slide_.querySelector(".tab.active").classList.remove("active");
      slide_.querySelectorAll(".tab")[nextPos_].classList.add("active");
    }
  }
  function goToPrevTab(slide) {
    var slide_ = slide,
      currentSlide_ = slide_.querySelector(".tab_menu li.active"),
      currentPos_ = currentSlide_
        ? currentSlide_.getAttribute("data-pos")
        : parseInt(
          slide_
            .querySelector(".tab_menu li.filled:last-child")
            .getAttribute("data-pos")
        ) + 1,
      prevPos_ = parseInt(currentPos_) - 1,
      prevPosExists_ = slide_.querySelector(
        '.tab_menu li[data-pos="' + prevPos_ + '"]'
      ),
      prevSlide_;

    if (prevPosExists_) {
      prevSlide_ = prevPosExists_;

      if (currentSlide_) currentSlide_.classList.remove("active");
      prevSlide_.classList.add("active");
      if ($(slide_).hasClass("tab_container--symptomchecker")) {
        $(slide_).find(".tab_menu li").removeClass("filled");
        $(slide_).find(".tab_menu .active").prevAll().addClass("filled");
      }

      slide_.querySelector(".tab.active").classList.remove("active");
      slide_.querySelectorAll(".tab")[prevPos_].classList.add("active");
    }
  }

  /* carousel for tab, rotate infinite */
  var autoslide_ = document.querySelectorAll(".tab_container[data-auto-slide]");
  if (autoslide_) {
    for (var i = 0; i < autoslide_.length; i++) {
      autoSlideTabs(autoslide_[i]);
    }
  }
  function autoSlideTabs(slide) {
    var slide_ = slide,
      sliderTimerExists_ = slide_.getAttribute("data-auto-slide");

    var slideTabs_ = setInterval(
      function () {
        goToNextTab(slide_, true);
      },
      sliderTimerExists_ ? sliderTimerExists_ * 1000 : 10000
    );

    slide_.addEventListener("click", function () {
      clearInterval(slideTabs_);
    });
  }
  /* /carousel for tab, rotate infinite */

  $(".tab_menu li").on("click", function () {
    var pos = $(this).attr("data-pos");
    if (pos) {
      $(this).closest(".tab_container").find("li").removeClass("active");
      $(this).addClass("active");
      if (
        $(this)
          .closest(".tab_container")
          .hasClass("tab_container--symptomchecker")
      ) {
        $(this).closest(".tab_container").find("li").removeClass("filled");
        $(this).prevAll().addClass("filled");
      }
      $(this).closest(".tab_container").find(".tab").removeClass("active");
      $(this).closest(".tab_container").find(".tab").eq(pos).addClass("active");
    }
  });

  $(".tabs").each(function () {
    var maxHeight = 0;
    var tabs = $(this).find(".tab");
    tabs.each(function () {
      this.style.display = "block";
      if (this.scrollHeight > maxHeight) {
        maxHeight = this.scrollHeight;
      }
      this.style.display = "";
    });

    tabs.css("min-height", maxHeight);
  });

  /*  symptomchecker - tracking abschliessen */
  $("body").on(
    "click",
    ".tab_container--symptomchecker .js-last-tab",
    function (e) {
      var this_ = $(this),
        slide_ = this_.closest(".tab_container");
      e.preventDefault();

      slide_.find(".tab_menu li").removeClass("active").addClass("filled");
      slide_.find(".tab.active").removeClass("active");
      slide_.find(".tab:last-child").addClass("active");
    }
  );
  /* /symptomchecker - tracking abschliessen */

  /* /symptomchecker - generate pdf */
  $("body").on(
    "click",
    ".tab_container--symptomchecker .tab_content--symptoms-complete .symptomchecker_submit",
    function (e) {
      symptomcheckerForm = JSON.stringify(
        $("#symptomcheckerform").serializeArray()
      );
      GeneratePDF(symptomcheckerForm);
      e.preventDefault();
    }
  );
  /* /symptomchecker - generate pdf */

  $.urlParam = function (name) {
    var results = new RegExp("[?&]" + name + "=([^&#]*)").exec(
      window.location.href
    );
    if (results === null) {
      return null;
    } else {
      return decodeURI(results[1]) || 0;
    }
  };

  //if ($.urlParam('loggedin')) {
  //  $("body").addClass("loggedin");
  //}
  // blue submenu

  /**
   * extended script to handle sub dropdowns
   * 2021.03.12 Sven Janßen
   **/
  if ($(".quickmenu").length > 0) {
    if (
      $(".quickmenu_mobile").length <= 0 ||
      $(".quickmenu_mobile").html() == ""
    ) {
      var el = $(".quickmenu_content > ul > li > a").first().clone();
      if ($(".quickmenu_mobile").length <= 0) {
        $(".quickmenu_content").prepend(el);
        el.attr("href", "#");
        el.addClass("quickmenu_mobile");
      } else {
        $(".quickmenu_mobile").html(el.html());
      }
    }

    $(".quickmenu_mobile").on("click", function () {
      if ($(this).hasClass("active")) {
        $(this).removeClass("active");
        $(".quickmenu_content > ul").slideUp();
      } else {
        $(this).addClass("active");
        $(".quickmenu_content > ul").slideDown();
      }
      return false;
    });

    $(".quickmenu_content ul a:not(.quickmenu_folder-btn").on(
      "click",
      function () {
        if ($(window).width() < 768) {
          $(".quickmenu_mobile").html($(this).html());
          $(".quickmenu_mobile").removeClass("active");
          $(".quickmenu_content > ul").slideUp();
        }
      }
    );

    $("header").css("margin-bottom", 40);

    // added functions for dropdowns
    if ($(".quickmenu_dropdown").length > 0) {
      var buttons = $(".quickmenu_folder-btn");
      for (var i = 0; i < buttons.length; i++) {
        $(buttons[i]).next("ul").hide();
      }
      var lastActiveQuickmenuDropDown = null;

      $(document).click(function (event) {
        //close on clickoutside
        var $target = $(event.target);
        if (
          !$target.closest(".quickmenu_content").length &&
          $(".quickmenu_content > ul").is(":visible")
        ) {
          $(".quickmenu_mobile.active").trigger("click");
        }
      });

      $(".quickmenu_dropdown").each(function () {
        $(this).prepend(
          '<li class="quickmenu__btn-container"><button type="button" class="quickmenu__btn">' +
          $(this).prev().text() +
          "</button></li>"
        );
        $(this)
          .find(".quickmenu__btn")
          .bind("click", function (e) {
            e.preventDefault();
            $(".quickmenu_content>ul>li").removeClass("passive");
            $(this)
              .parents(".quickmenu_dropdown")
              .slideUp(200, function () {
                $(this).removeClass("active").prev("a").removeClass("active");
              });
          });
      });

      $(".quickmenu_folder-btn").bind("click", function (e) {
        e.preventDefault();
        e.stopPropagation();
        var target = $(this);
        if (
          lastActiveQuickmenuDropDown !== null &&
          lastActiveQuickmenuDropDown != this
        ) {
          $(lastActiveQuickmenuDropDown)
            .next("ul")
            .slideUp(200, function () {
              $(this).removeClass("active").prev("a").removeClass("active");
            });
          lastActiveQuickmenuDropDown = null;
        }
        if (target.hasClass("active")) {
          target.next("ul").slideUp(200, function () {
            target.removeClass("active").next("ul").removeClass("active");
          });
          lastActiveQuickmenuDropDown = null;
        } else {
          lastActiveQuickmenuDropDown = this;
          target.addClass("active").next("ul").addClass("active");
          target.next("ul").slideDown(200, function () {
            $(".quickmenu_content>ul>li").addClass("passive");
            target.parents("li.col").removeClass("passive");
          });
        }
      });
    }
  }

  window.login = function () {
    if ($(".searchClicker").length == 0) {
      closeMobileNav();

      if ($(".doccheck").length != 0) return;

      var clickDiv = $("<div>").addClass("searchClicker");
      $("body").append(clickDiv);

      var loginLayer = $(doccheckFormHtml);
      loginLayer.attr("tabindex", "-1");
      loginLayer.css("box-shadow", "0px 0px 10px rgba(0, 0, 0, 0.5)");
      clickDiv.append(loginLayer);

      const DCREFERER = document.querySelector('[name="dc_iframe_referer"]');
      if (!DCREFERER) return;
      DCREFERER.value += location.search + location.hash;

      const mediaQueryList = window.matchMedia(
        "screen and (max-width : 1023px)"
      );
      const closeDetails = function () {
        const dcInfo = document.querySelector(".doccheck__info-2");
        if (!dcInfo) return;
        dcInfo.removeAttribute("open");
      };
      const openDetails = function () {
        const dcInfo = document.querySelector(".doccheck__info-2");
        if (!dcInfo) return;
        dcInfo.setAttribute("open", "open");
      };
      const onMatchMedia = function (event) {
        if (event.matches) {
          closeDetails();
        } else {
          openDetails();
        }
      };
      mediaQueryList.addEventListener("change", onMatchMedia, false);
      if (mediaQueryList.matches) closeDetails();

      clickDiv.on("click", function (e) {
        if (e.currentTarget.className == "searchClicker") {
          $(".searchClicker").fadeOut(function () {
            $(".searchClicker").remove();
          });
          $(".searchfield").slideUp("fast");
        }
      });

      loginLayer.on("click", function (e) {
        // e.preventDefault();
        e.stopPropagation();
      });
      $(clickDiv).fadeIn(function () {
        loginLayer.focus();
      });
    }
    return false;
  };

  // login layer
  $(".login").on("click", login);

  function closeMobileNav() {
    $("nav .menu_mobile").removeClass("visible");
    $("body").css("overflow", "auto");
  }

  var minWidth = $(".quickmenu_content").width();
  var maxWidth = $(window).width();
  var diffWidth = maxWidth - minWidth;
  var targetScroll =
    $(".keyvisual").height() - $(".quickmenu_content").height() * 0.5;
  var topNav = document.getElementsByClassName("topnav");
  //console.log('topnav length', topNav.length);
  $(window).resize(function () {
    maxWidth = $(window).width();
    diffWidth = maxWidth - minWidth;
  });

  function setNavScrolled(scrollPos) {
    if (topNav.length > 0) {
      if (scrollPos > 24 && !topNav[0].classList.contains("scrolled")) {
        topNav[0].classList.add("scrolled");
      } else if (scrollPos <= 24 && topNav[0].classList.contains("scrolled")) {
        topNav[0].classList.remove("scrolled");
      }
    }
  }

  setNavScrolled($(window).scrollTop());

  $(window).scroll(function () {
    var scrollPos = $(window).scrollTop();

    setNavScrolled(scrollPos);

    if ($("header .quickmenu").length) {
      var menuPos = scrollPos - $(".keyvisual").height();

      if (maxWidth > 768) {
        // quickmenu width
        if ($(".quickmenu_bg").length > 0) {
          var p = 1 - (targetScroll - scrollPos) / targetScroll;
          $(".quickmenu_bg").width(minWidth + diffWidth * p);
          $(".quickmenu_bg").css(
            "left",
            maxWidth * 0.5 - $(".quickmenu_bg").width() * 0.5 + "px"
          );
        }

        // product picker
        if ($(".product_picker").length > 0) {
          if (menuPos < -200) {
            // remove fixed sub
            if ($(".quickmenu_content ul").hasClass("subFixed")) {
              $(".quickmenu_content ul .subFixedLi").remove();
              $(".quickmenu_content ul").removeClass("subFixed");
            }
          } else {
            // make sub fixed
            if ($(".quickmenu_content ul").hasClass("subFixed")) {
            } else {
              var activeText = $(".product_picker .active").html();

              var pp = $(".product_picker ul").clone();
              var link = $("<a>").html(activeText);
              var li = $("<li>").addClass("col subFixedLi");
              li.append(link.append(pp));
              $(".quickmenu_content ul").prepend(li);
              $(".quickmenu_content ul").addClass("subFixed");
            }
          }
        }

        if (menuPos > 5) {
          $("header .quickmenu").addClass("fixed");
        } else {
          $("header .quickmenu").removeClass("fixed");
        }
      } else {
        if (menuPos > -20) {
          $("header .quickmenu").addClass("fixed");
        } else {
          $("header .quickmenu").removeClass("fixed");
        }
      }
    }
  });

  $("nav .search").on("click", function () {
    closeMobileNav();

    if ($(".searchfield").is(":visible")) {
      $(".searchfield").slideUp("fast");
      $(".searchClicker").fadeOut(function () {
        $(".searchClicker").remove();
      });
    } else {
      $(".searchfield").slideDown("fast");
      var clickDiv = $("<div>").addClass("searchClicker search");
      $("body").append(clickDiv);
      clickDiv.on("click", function () {
        $(".searchClicker").fadeOut(function () {
          $(".searchClicker").remove();
        });
        $(".searchfield").slideUp("fast");
      });
      $(clickDiv).fadeIn();
    }
    return false;
  });

  $(".kontakt_box .kontakt_lasche").on("click", showHideContactForm);

  $("body").on(
    "click",
    ".kontakt_box .kontakt_tab .closeBtn",
    showHideContactForm
  );
  $("body").on("click", ".info-popup .kontakt_tab .closeBtn", function (e) {
    e.preventDefault();
    $(".info-popup-background").trigger("click");
  });

  function showHideContactForm() {
    $(".kontakt_box").toggleClass("kontakt_box--visible");
  }

  // slider
  if ($(".slider").length > 0) {
    $(".slider").slick({
      infinite: false,
      prevArrow: '<div class="slick_button slick-prev"></div>',
      nextArrow: '<div class="slick_button slick-next"></div>',
      dots: true,
    });
  }

  $(".timeline .slider_content").each(function () {
    var pointer = $("<div>").addClass("timeline_pointer");
    var dot = $("<div>").addClass("timeline_dot");
    var header = $(this).find(".slider_header");
    var headerHeight = $(this).find(".slider_header").height() * 0.5;
    var topPos = Math.abs(
      header.offset().top - $(this).offset().top + headerHeight
    );
    var leftOffset = $(this).offset().left;
    var leftPos = header.offset().left - 72 - leftOffset;
    var leftPointer = header.offset().left - 50 - leftOffset;
    var headerHeightAdd = -60;

    if (maxWidth < 1024) {
      leftPos = header.offset().left - 52 - leftOffset;
      leftPointer = header.offset().left - 30 - leftOffset;

      topPos -= 55;
      headerHeightAdd = -90;
      leftPos += 18;
      leftPointer += 10;
    }
    if (header.length > 0 && header.html().length > 0) {
      pointer.css("left", leftPointer + "px");
      pointer.css("top", topPos);
      dot.css("left", leftPos + "px");
      $(this).find(".container").append(pointer);
      $(this).find(".container").append(dot);
      var pHeight = pointer.height() - headerHeight;
      if (maxWidth < 1024) {
        pHeight = headerHeight;
      }

      pointer.css("height", Math.abs(pHeight + headerHeightAdd));
    }
  });

  // fix table with rowspan
  $("table:not(.no-automatic-background)").each(function () {
    var rowA = 0;
    var lastRowA = true;
    var lastRowRowspan = 0;
    $(this)
      .find("tr")
      .each(function () {
        var myRow = $(this);

        myRow.find("td").each(function () {
          if ($(this).attr("rowspan") != undefined) {
            lastRowRowspan = parseInt($(this).attr("rowspan")) - 1;
          }
        });

        if (lastRowA) {
          // dark
          myRow.addClass("dark");
          if (lastRowRowspan == 0) {
            lastRowA = !lastRowA;
          } else {
            lastRowRowspan--;
          }
        } else {
          // bright
          if (lastRowRowspan == 0) {
            lastRowA = !lastRowA;
          } else {
            lastRowRowspan--;
          }
        }
      });
  });

  // IE timeline
  if (getIEVersion()) {
    var img = $("img.slider_background.slider_background_main").wrap(
      "<div class='slider_background slider_background_main'>"
    );
    var img = $("img.slider_background").wrap(
      "<div class='slider_background'>"
    );
  }
  // congress wffm form
  $("form").each(function () {
    if ($(this).parents(".anmeldeformular").length > 0) {
      $(this).removeClass("form-inline");
      $(this).removeClass("text-left");

      $(this)
        .find("input")
        .each(function () {
          $(this).removeClass("form-control");
          $(this).removeClass("btn");
          $(this).removeClass("btn-default");
        });

      $(this)
        .find(".hidden")
        .each(function () {
          $(this).css("display", "none");
        });
    }
  });
});

function getIEVersion() {
  var sAgent = window.navigator.userAgent;
  var Idx = sAgent.indexOf("MSIE");

  if (Idx > 0) {
    return parseInt(sAgent.substring(Idx + 5, sAgent.indexOf(".", Idx)));
  } else if (!!navigator.userAgent.match(/Trident\/7\./)) {
    return 11;
  } else {
    return 0; //It is not IE
  }
}

// ScrollableTable -----

document.addEventListener("DOMContentLoaded", function (e) {
  function loadBindings() {
    $("table").each(function () {
      if ($(this).width() > $(this.parentNode).width()) {
        var wrap = $(this).wrap(
          '<div class="table-scrollable-container show-shadow-right">'
        );

        $(wrap).scroll(function () {
          if ($(this).scrollLeft() == 0) {
            $(this.parentNode).removeClass("show-shadow-left");
          } else if (
            $(this).scrollLeft() ==
            this.scrollWidth - $(this).width()
          ) {
            $(this.parentNode).removeClass("show-shadow-right");
          } else {
            $(this.parentNode).addClass("show-shadow-left show-shadow-right");
          }
        });
      }
    });
  }
  function init() {
    loadBindings();
  }
  init();
});

// /ScrollableTable -----

// videoplayerPopup
const VideoplayerPopup = (function () {
  "use strict";

  function loadBindings_() {
    $("body").on("click", ".js-videoplayer-popup", function (e) {
      if ($(".searchClicker").length === 0) {
        e.preventDefault();
        placePopup_(this);
      }
    });
  }
  function placePopup_(el) {
    const _CONTAINER = $(el).closest(".teaser_quer, .teaser_box"),
      _HEADLINE = _CONTAINER.find(".icon_row h2")[0]
        ? _CONTAINER.find(".icon_row h2")[0].innerHTML
        : "",
      _TEXT = _CONTAINER.find(".videoplayer__text")[0]
        ? _CONTAINER.find(".videoplayer__text")[0].innerHTML
        : "",
      _CLICKDIV = $('<div class="searchClicker mediathek"></div>'),
      _VIDEOHTML = el.getAttribute("data-video-html")
        ? el.getAttribute("data-video-html")
        : '<div class= "video-popup__video-container"><iframe class="video-popup__video" width="560" height="315" src="' +
        el.getAttribute("data-video") +
        '" frameborder="0" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowfullscreen=""></iframe></div>',
      _POPUP = $(
        '<div class="teaserbox video-popup">\
          ' +
        _VIDEOHTML +
        '\
          <div class="video-popup__content">\
            <h2 class="video-popup__title">' +
        _HEADLINE +
        '</h2>\
            <p class="video-popup__description">' +
        _TEXT +
        "</p>\
          </div>\
        </div>"
      );

    _CLICKDIV.append(_POPUP);

    _CLICKDIV.on("click", function (e) {
      if (e.currentTarget.classList.contains("searchClicker")) {
        $(".searchClicker").fadeOut(function () {
          $(".searchClicker").remove();
        });
        $(".searchfield").slideUp("fast");
      }
    });
    _POPUP.on("click", function (e) {
      e.stopPropagation();
    });

    if ($(".searchClicker").length === 0) {
      $("body").append(_CLICKDIV);
      $(_CLICKDIV).fadeIn();
    } else {
      $(".searchClicker").replaceWith(_CLICKDIV);
      _CLICKDIV.css("display", "block");
    }
    if ( typeof initRefPopover === "function" && _POPUP[0]) {
      initRefPopover(_POPUP[0]);
    }
  }

  return {
    init: loadBindings_,
  };
})();
VideoplayerPopup.init();
// IvideoplayerPopup

// Image popup
var imagePopup = (function ($) {
  function loadBindings() {
    $("body").on("click", ".js-image-popup", function (e) {
      e.preventDefault();
      placePopup(this);
    });
  }

  function placePopup(_this) {
    var _CLICKDIV = $('<div class="searchClicker mediathek">'),
      _TITLE = _this.getAttribute("data-title"),
      _DESCRIPTION = _this.getAttribute("data-description"),
      _POPUP = $(
        '<div class="teaserbox video-popup">\
          <img src="' +
        _this.href +
        '" alt="' +
        _this.getAttribute("data-alt") +
        '">\
          <div class="video-popup__content">' +
        (_TITLE
          ? '<h2 class= "video-popup__title">' + _TITLE + "</h2>"
          : "") +
        (_DESCRIPTION
          ? '<p class="video-popup__description">' + _DESCRIPTION + "</p>"
          : "") +
        "</div></div>"
      );

    _CLICKDIV.append(_POPUP);

    _CLICKDIV.on("click", function (e) {
      if (e.currentTarget.classList.contains("searchClicker")) {
        $(".searchClicker").fadeOut(function () {
          $(".searchClicker").remove();
        });
        $(".searchfield").slideUp("fast");
      }
    });
    _POPUP.on("click", function (e) {
      e.stopPropagation();
    });

    if ($(".searchClicker").length === 0) {
      $("body").append(_CLICKDIV);
      $(_CLICKDIV).fadeIn();
    } else {
      $(".searchClicker").replaceWith(_CLICKDIV);
      _CLICKDIV.css("display", "block");
    }
  }

  return {
    init: loadBindings,
  };
})(jQuery);
imagePopup.init();
// /Image popup

// Video popup
var videoPopup = (function ($) {
  "use strict";

  function getVideoParams() {
    /** get query parameters from the location href istead of location.search
     * we use the href because the return url of doccheck changes & to &#38;
     * where the # removes the value from the location search object
     */
    const url = location.href;
    if (url.indexOf("?") === -1) return [];
    let searchQuery = url.split("?")[1];
    if (searchQuery !== "") {
      searchQuery = searchQuery.replace("&#38;", "&");
      return Utils.queryToParams(searchQuery);
    }
    return [];
  }

  function updateUrl(e) {
    const params = Utils.queryToParams(location.search);
    params.video = $(this).attr("data-itemid");
    if (!params.video) return;

    e.preventDefault();
    history.pushState(
      null,
      null,
      location.origin +
      location.pathname +
      Utils.paramsToQuery(params) +
      location.hash
    );
    placePopup(params.video);
  }

  function onNavigation() {
    // const params = Utils.queryToParams(location.search);
    const params = getVideoParams();
    if (params.video) {
      placePopup(params.video);
    } else {
      $(".searchClicker.mediathek").fadeOut(function () {
        $(".searchClicker.mediathek").remove();
      });
    }
  }

  function loadBindings() {
    $("body").on("click", ".js-video-popup", updateUrl);

    window.addEventListener("popstate", onNavigation);
    onNavigation();
  }

  function decodeEntities(encodedString) {
    var textArea = document.createElement("textarea");
    textArea.innerHTML = encodedString;
    return textArea.value;
  }

  function placePopup(videoItemId) {
    if ($(".searchClicker.mediathek").attr("data-itemid") == videoItemId)
      return;

    var _CLICKDIV = $(
      '<div class="searchClicker mediathek" data-itemid="' +
      videoItemId +
      '">'
    ),
      _POPUP;

    var ajaxUrl = "/alexiongermanyapi/VideoGallery/VideoLayer";
    $.ajax({
      url: ajaxUrl,
      type: "get",
      async: false,
      data: {
        videoItemId: videoItemId,
        playVideoText: window.VIDEOGALLERY_PLAYVIDEOTEXT,
        relatedVideosText: window.VIDEOGALLERY_RELATEDVIDEOSTEXT,
      },
      success: function (data) {
        if (typeof data === "object") {
          var loginElements = document.getElementsByClassName("login");
          if (loginElements !== undefined && loginElements.length > 0) {
            loginElements[0].click();
          }
        } else {
          (_POPUP = $(decodeEntities(data))), _CLICKDIV.append(_POPUP);
        }
      },
      error: function (data) {
        alert("error loading video layer");
      },
    });

    // if the pop up is empty, return to avoid error
    if (_POPUP === undefined) {
      return;
    }

    _CLICKDIV.on("click", function (e) {
      const params = Utils.queryToParams(location.search);
      delete params.video;
      history.pushState(
        null,
        null,
        location.origin +
        location.pathname +
        Utils.paramsToQuery(params) +
        location.hash
      );
      $(".searchClicker.mediathek").fadeOut(function () {
        $(".searchClicker.mediathek").remove();
      });
      $(".searchfield").slideUp("fast");
    });
    _POPUP.on("click", function (e) {
      e.stopPropagation();
    });
    _POPUP.on("click", ".js-video-popup", updateUrl);

    if ($(".searchClicker").length === 0) {
      $("body").append(_CLICKDIV);
      $(_CLICKDIV).fadeIn();
    } else {
      $(".searchClicker").replaceWith(_CLICKDIV);
      _CLICKDIV.css("display", "block");
    }
    if ( typeof initRefPopover === "function" && _POPUP[0]) {
      initRefPopover(_POPUP[0]);
    }
  }
  return {
    init: loadBindings,
  };
})(jQuery);
videoPopup.init();
// /Video popup

function GeneratePDF(symptomcheckerForm) {
  var url = "/alexiongermanyapi/SymptomTracker/GetPDF";
  var model = { FormData: symptomcheckerForm, DsItemId: SYMPTOMCHECKER_ID };

  $.ajax({
    url: url,
    type: "post",
    contentType: "application/json",
    data: JSON.stringify(model),
    processData: false,
    success: function (data) {
      var apiUrl = "/alexiongermanyapi/SymptomTracker/GetPDF?pdf=";
      var url = apiUrl + data;
      window.location.href = url;
    },
    error: function (jqXhr, textStatus, errorThrown) {
      console.log(errorThrown);
    },
  });
}

// Accordion
var accordion = (function () {
  "use strict";

  function bindings() {
    var tab_,
      tabAutoHeight_,
      hash_ = window.location.hash,
      tabHash_ = $('.accordion__tab[href="' + hash_ + '"]');

    $(".accordion__content").css("display", "none");

    if (hash_ && tabHash_) {
      tabHash_.addClass("accordion__tab--active");
      tabHash_.next().css("display", "");
    }

    $("body").on("click", ".accordion__tab", function (e) {
      tab_ = $(this).next();

      if ($(this).hasClass("accordion__tab--active")) {
        tab_.height(tab_.outerHeight()).animate(
          {
            height: 0,
          },
          250,
          function () {
            tab_.css({
              display: "none",
              height: "",
            });
            history.replaceState(
              null,
              null,
              location.href.replace(location.hash, "")
            );
          }
        );
      } else {
        tab_.css("display", "");
        tabAutoHeight_ = tab_.css("height", "auto").outerHeight();
        tab_.height("0").animate(
          {
            height: tabAutoHeight_,
          },
          250,
          function () {
            tab_.css("height", "");
          }
        );
      }

      $(this).toggleClass("accordion__tab--active");
    });
  }
  return {
    init: function () {
      bindings();
    },
  };
})();
accordion.init();
// /Accordion

window.selectTab = function selectTab(id) {
  $(".kontakt_box .dot.active").removeClass("active");

  if (id === 0) {
    $(".kontakt_tab").removeClass("active");
    $(".kontakt_tab").eq(id).addClass("active");
  }

  var nextPage = id + 1;
  var pages = $(".kontakt_box .dot").length;
  if (nextPage === pages) {
    $(".form-submit-border").removeClass("hidden");
  } else {
    $(".form-submit-border").addClass("hidden");
  }

  var newTabIndex = id - 1;
  var previousTabIndex = id - 2;
  $(".wffmcustomtab").eq(previousTabIndex).removeClass("active");
  $(".kontakt_box .dot")
    .eq(id)
    .html(id + 1);
  $(".kontakt_box .dot").eq(id).addClass("active");
  $(".wffmcustomtab").eq(newTabIndex).addClass("active");
  $(".kontakt_box .dot").eq(id).removeClass("checked");
  $(".wffmcustomtab").eq(newTabIndex).removeClass("checked");

  for (var i = 0; i < id; ++i) {
    $(".kontakt_box .dot").eq(i).html("\u2714").addClass("checked");
  }

  return false;
};

document.addEventListener("DOMContentLoaded", function (event) {
  // show/hide field in sitecore forms

  jQuery(".hideabletextfieldtext").nextAll(".hideabletextfield").slideToggle();

  jQuery("body").on("click", ".hideabletextfieldtext", function () {
    var showHideInput = $(this).nextAll(".hideabletextfield");

    showHideInput.slideToggle();
    $(this).toggleClass("open");
  });

  //jQuery('form[id]').on('submit', function (e) {
  //  if (!e.target.querySelector('.input-validation-error')) {
  //    $('.kontakt_box .dot').eq(2).removeClass('active');
  //    $('.kontakt_box .dot').eq(2).html('\u2714').addClass('checked');
  //    $('.kontakt_box .kontakt_tab').removeClass('active');
  //    $('.kontakt_box .kontakt_tab.text-center').addClass('active');
  //  }
  //});

  // search page
  jQuery("body").on("click", "a.loadMore", function (e) {
    e.preventDefault();
    searchPage++;
    $.ajax({
      url: "/alexiongermanyapi/Search/SearchPageResults",
      type: "get",
      data: {
        searchTerm: searchTerm,
        linkText: searchResultLinkText,
        page: searchPage,
        pageSize: searchPageSize,
      },
      success: function (data) {
        if (data != null && data != typeof undefined && data != "") {
          $(".searchcontainer").append(data);
        } else {
          $("a.loadMore").closest(".row").remove();
        }
      },
      error: function (data) {
        //alert("form not present: " + form);
        $("a.loadMore").closest(".row").remove();
      },
    });
  });

  jQuery("body").on("click", ".searchfield a.searchbutton", function () {
    if (
      searchPageUrl != null &&
      searchPageUrl != typeof undefined &&
      searchPageUrl != ""
    ) {
      var searchField = jQuery("input[name = 'suche']");
      if (searchField != null && searchField != typeof undefined) {
        var searchTerm = jQuery(searchField).val();
        if (searchTerm != null && searchTerm != typeof undefined) {
          window.location.href = searchPageUrl + "?searchTerm=" + searchTerm;
        }
      }
    }
  });

  jQuery("body").on("keypress", "input[name='suche']", function (e) {
    if (
      e.which == 13 &&
      searchPageUrl != null &&
      searchPageUrl != typeof undefined &&
      searchPageUrl != ""
    ) {
      var searchTerm = jQuery(this).val();
      window.location.href = searchPageUrl + "?searchTerm=" + searchTerm;
    }
  });
});

// "pdf" overlay
const pdfOverlay = (function () {
  "use strict";

  function loadBindings_() {
    $("body").on("click", ".pdf-overlay", function (e) {
      e.preventDefault();
      if ($(".searchClicker").length === 0) {
        var clickDiv_ = $('<div class="searchClicker">'),
          url_ = this.href || this.querySelector("a").href,
          content_ = $(
            '<div class="teaserbox teaserbox--pdf-overlay"><img src="' +
            url_ +
            '" draggable="false" /></div>'
          );

        clickDiv_.append(content_);
        $("body").append(clickDiv_);

        clickDiv_.on("click", function (e) {
          if (e.currentTarget.classList.contains("searchClicker")) {
            $(clickDiv_).fadeOut(function () {
              $(clickDiv_).remove();
            });
            $(".searchfield").slideUp("fast");
          }
        });
        content_.on("click", function (e) {
          e.stopPropagation();
        });
        content_.on("contextmenu", function (e) {
          e.preventDefault();
          return false;
        });
        $(clickDiv_).fadeIn();
      }
      return false;
    });
  }

  return {
    init: loadBindings_,
  };
})();

pdfOverlay.init();

var studyPopup = (function () {
  "use strict";

  function openArticle() {
    $("body").on("click", ".js-study-open-article", function () {
      openPopup(this, "image");
    });
  }

  function openVideo() {
    $("body").on("click", ".js-study-open-video", function () {
      openPopup(this, "video");

      $("video").each(function () {
        this.pause();
        this.currentTime = 0;
      });

      var studyMenu = document.querySelectorAll(
        ".js-study-menu .custom-dropdown__option"
      );
      for (var i = 0; i < studyMenu.length; i++) {
        if (studyMenu[i].textContent === this.getAttribute("data-title")) {
          studyMenu[i].click();
          break;
        }
      }
    });
  }

  function openPopup(btn, type) {
    var popup = document.querySelector(
      ".study-tabber__tab-content--visible .study__popup"
    ),
      popupContent = popup.querySelector(".study__popup-content"),
      studyTab = $(popup).closest(".study-tabber__tab-content"),
      top = studyTab.find("h1")[0].offsetTop;

    popup.style.top = top + "px";
    popupContent.innerHTML =
      '<div class="study__popup-headline">' +
      btn.getAttribute("data-title") +
      "</div>";

    setTimeout(function () {
      studyTab.addClass("study-tabber__tab-content--popup-visible");
    }, 200);

    switch (type) {
      case "image":
        const mobile = btn.hasAttribute("data-mobile-src")
          ? '<source media="(max-width: 768px)" srcset="' +
          btn.getAttribute("data-mobile-src") +
          '">'
          : "";
        popupContent.insertAdjacentHTML(
          "beforeend",
          "<picture>" +
          mobile +
          '<img class="study__popup-media" src="' +
          btn.getAttribute("data-src") +
          '" /></picture>'
        );
        break;
      case "video":
        if (btn.getAttribute("data-src-html")) {
          $(popupContent).append(btn.getAttribute("data-src-html"));
        } else if (btn.getAttribute("data-src")) {
          if (btn.getAttribute("data-src").indexOf("http") === -1) {
            popupContent.insertAdjacentHTML(
              "beforeend",
              '<div class="study__popup-media study__popup-video-container"><video class="study__popup-video" autoplay controls><source src="' +
              btn.getAttribute("data-src") +
              '" type="video/mp4"></source></video></div>'
            );
          } else {
            popupContent.insertAdjacentHTML(
              "beforeend",
              '<div class="study__popup-media study__popup-video-container"><iframe class="study__popup-video" src="' +
              btn.getAttribute("data-src") +
              '" frameborder="0" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>'
            );
          }
        }
        break;
      default:
        break;
    }

    closePopup();
    popup.classList.add("study__popup--visible");

    $("html, body").animate({ scrollTop: 0 }, 200);
  }

  function closePopup() {
    var popup = document.querySelector(
      ".study-tabber__tab-content--visible .study__popup"
    ),
      studyTab = $(popup).closest(".study-tabber__tab-content");

    $("body").bind("click", checkClose);

    function checkClose(e) {
      if (
        $(e.target).closest(".js-close-study-popup").length > 0 ||
        $(e.target).closest(".study__popup-content, ").length === 0
      ) {
        popup.classList.remove("study__popup--visible");
        popup.classList.add("study__popup--hide");
        studyTab.removeClass("study-tabber__tab-content--popup-visible");

        setTimeout(function () {
          popup.classList.remove("study__popup--hide");
          popup.querySelector(".study__popup-content").innerHTML = "";
        }, 200);
        //document.querySelectorAll('.js-study-menu .custom-dropdown__option')[0].click();
        $("body").unbind("click", checkClose);
      }
    }
  }

  return {
    openArticle: openArticle,
    openVideo: openVideo,
  };
})();
studyPopup.openArticle();
studyPopup.openVideo();

var LoadStudyTab = (function () {
  "use strict";

  const TARGET_LIST = {
    "komplement-inhibitor-naiv": "purple",
    "c5-inhibitor-erfahren": "orange",
  };

  function getPageHashFromLink() {
    var url = document.location.href;
    var parts = url.split("#");
    if (parts.length > 1) {
      return parts.pop();
    }
    return null;
  }

  function getHashByColor(color) {
    for (var i in TARGET_LIST) {
      if (TARGET_LIST[i] == color) {
        return i;
      }
    }
    return null;
  }

  function init_() {
    var hashValue = getPageHashFromLink();
    if (hashValue !== null && TARGET_LIST[hashValue]) {
      var element = $(
        '.study-circles__circle[data-color="' + TARGET_LIST[hashValue] + '"]'
      );
      openTab(
        element.attr("data-studytabid"),
        TARGET_LIST[hashValue],
        hashValue
      );
    }
    $("body").on("click", ".js-load-study-tab", function (e) {
      const _STUDY_TAB = $(this);
      const _STUDY_TAB_ID = _STUDY_TAB.attr("data-studytabid");
      const _STUDY_TAB_COLOR = _STUDY_TAB.attr("data-color");
      hashValue = getHashByColor(_STUDY_TAB_COLOR);
      if (hashValue && hashValue !== null) {
        window.history.pushState(
          "Begehbare Studie",
          "Begehbare Studie",
          document.location.href.split("#")[0] + "#" + hashValue
        );
      }
      if (_STUDY_TAB_ID !== "" && _STUDY_TAB_ID !== typeof "undefined") {
        openTab(_STUDY_TAB_ID, _STUDY_TAB_COLOR, null);
      }
    });
  }

  function updateRefererLinkInFormular(hashValue) {
    if ($("[name='dc_iframe_referer']")) {
      var value = $("[name='dc_iframe_referer']").val();
      $("[name='dc_iframe_referer']").val(
        value.split("#")[0] + "#" + hashValue
      );
    }
  }

  function openTab(_STUDY_TAB_ID, _STUDY_TAB_COLOR, hashValue) {
    const _AJAX_URL = "/alexiongermanyapi/study/tab";
    var studyTabContent_, studyTabContentHtml_;
    if (_STUDY_TAB_ID !== "" && _STUDY_TAB_ID !== typeof "undefined") {
      studyTabContent_ = $(
        '.study-tabber__tab-content[data-studytabid="' + _STUDY_TAB_ID + '"]'
      );
      if (
        studyTabContent_ === null ||
        studyTabContent_ === typeof "undefined" ||
        studyTabContent_.length === 0
      ) {
        $.ajax({
          url: _AJAX_URL,
          type: "get",
          async: false,
          data: { tabId: _STUDY_TAB_ID },
          success: function (data) {
            $("video.study-teaserboxs__back-background").each(function () {
              this.pause();
              this.currentTime = 0;
            });

            $(".study-tabber__tab").removeClass("study-tabber__tab--active");
            $(
              '.study-tabber__tab[data-studytabid="' + _STUDY_TAB_ID + '"]'
            ).addClass("study-tabber__tab--active");
            $(".study-circles").addClass("study-circles--hidden");

            $(".study-tabber__tab-content").removeClass(
              "study-tabber__tab-content--visible"
            );
            studyTabContentHtml_ =
              '<div class="study-tabber__tab-content study-tabber__tab-content--visible" data-color="' +
              _STUDY_TAB_COLOR +
              '" data-studytabid="' +
              _STUDY_TAB_ID +
              '">' +
              data +
              "</div>";
            $(".study-tabber__tab-contents").append(studyTabContentHtml_);
          },
          error: function (data) {
            console.log("please login first to view the content");
            if (
              hashValue &&
              hashValue !== null &&
              typeof hashValue != "undefined"
            ) {
              updateRefererLinkInFormular(hashValue);
            }
          },
        });
      } else {
        $(".study-tabber__tab").removeClass("study-tabber__tab--active");
        $(
          '.study-tabber__tab[data-studytabid="' + _STUDY_TAB_ID + '"]'
        ).addClass("study-tabber__tab--active");
        $(".study-circles").addClass("study-circles--hidden");

        $(".study-tabber__tab-content").removeClass(
          "study-tabber__tab-content--visible"
        );
        $(
          '.study-tabber__tab-content[data-studytabid="' + _STUDY_TAB_ID + '"]'
        ).addClass("study-tabber__tab-content--visible");
      }
      localStorage.setItem("displayStudyCircles", "true");
    }
  }
  return {
    init: init_,
  };
})();
LoadStudyTab.init();

var StudyTeaser = (function () {
  "use strict";

  const ACTIVE_CLASS = "study-teaserboxs__teaserbox--active";

  function init() {
    $("body").on("click", ".study-teaserboxs__teaserbox", function () {
      const ACTIVE = $(this).hasClass(ACTIVE_CLASS);
      $("." + ACTIVE_CLASS).removeClass(ACTIVE_CLASS);
      if (!ACTIVE) this.classList.add(ACTIVE_CLASS);

      $("video").each(function () {
        this.pause();
        this.currentTime = 0;
      });

      const VIDEO = this.querySelector(
        "video.study-teaserboxs__back-background"
      );
      if (!ACTIVE && VIDEO) VIDEO.play();
    });
  }

  return {
    init: init,
  };
})();
StudyTeaser.init();

var StudyIntro = (function () {
  "use strict";

  function init() {
    const VIDEO = document.querySelector(".study__intro-video"),
      BUTTON = document.querySelector(".study__intro-play"),
      INTRO = document.querySelector(".study__intro");
    if (!INTRO || !VIDEO || !BUTTON || window.innerWidth < 768) return;

    INTRO.addEventListener("click", function () {
      if (VIDEO.paused) {
        $("video").each(function () {
          this.pause();
        });
        VIDEO.play();
      } else {
        VIDEO.pause();
      }
    });

    var FOOTER = document.querySelector(".footer-disclaimer, footer");
    if (!FOOTER) return;

    var LOGO = document.querySelector(".study__intro-logo");
    var PROF = document.querySelector(".study__intro-prof");
    function updateIntro() {
      if (FOOTER.getBoundingClientRect().top < window.innerHeight) {
        VIDEO.style.position = "absolute";
        BUTTON.style.position = "absolute";
        if (LOGO) LOGO.style.position = "absolute";
        if (PROF) PROF.style.position = "absolute";
        return;
      }
      VIDEO.removeAttribute("style");
      BUTTON.removeAttribute("style");
      if (LOGO) LOGO.removeAttribute("style");
      if (PROF) PROF.removeAttribute("style");
    }
    updateIntro();
    window.addEventListener("scroll", updateIntro);
    window.addEventListener("click", updateIntro);
  }

  return {
    init: init,
  };
})();
StudyIntro.init();

var DisplayStudyCircles = (function () {
  "use strict";

  function display() {
    var storageValue = localStorage.getItem("displayStudyCircles");
    if (storageValue === "false" && storageValue !== null) return;

    $(".study-circles").removeAttr("hidden");
  }

  return {
    display: display,
  };
})();

DisplayStudyCircles.display();

/* AD PAGES */
$(".adm-slider:not(.adm-slider--arrows):not(.adm-slider--exp)").slick({
  arrows: false,
  dots: true,
});

$(".adm-slider--arrows:not(.adm-slider--exp)").slick({
  arrows: true,
  dots: true,
  prevArrow:
    '<button type="button" aria-label="Previous" class="adm-slider--arrow adm-slider--arrow-prev"><svg focusable="false" viewBox="0 0 13 21"><path d="M12.929 17.4645l-2.8285 2.8284L.201 10.3934 3.0294 7.565z"/><path d="M3.021 13.2352L.1926 10.4068 10.0921.5073l2.8284 2.8285z"/></svg></button>',
  nextArrow:
    '<button type="button" aria-label="Next" class="adm-slider--arrow adm-slider--arrow-next"><svg focusable="false" viewBox="0 0 13 21"><path d="M.071 17.4645l2.8285 2.8284 9.8995-9.8995L9.9706 7.565z"/><path d="M9.979 13.2352l2.8284-2.8284L2.9079.5073.0795 3.3358z"/></svg></button>',
});

/* INFO POPUP */
var InfoPopup = (function () {
  "use strict";

  function display() {
    Array.prototype.forEach.call(
      document.querySelectorAll(".info-popup-background"),
      function (infoPopup) {
        const infoPopupKey = "display-info-popup-" + infoPopup.dataset.id;
        if (localStorage.getItem(infoPopupKey)) return;

        var delay = +infoPopup.dataset.delay;
        if (delay > 0) {
          var time = delay * 1000;
          var delta = 100;

          var tid = setInterval(function () {
            if (document.hidden) return;
            time -= delta;
            if (time <= 0) {
              clearInterval(tid);
              // time passed - do your work
              infoPopup.removeAttribute("hidden");
            }
          }, delta);
        } else {
          infoPopup.removeAttribute("hidden");
        }

        infoPopup.addEventListener("click", function (event) {
          if (
            $(event.target).closest(".info-popup").length &&
            !$(event.target).closest(".info-popup__close").length &&
            !$(event.target).closest(".closeBtn").length
          ) {
            return;
          }

          $(infoPopup).fadeOut(function () {
            localStorage.setItem(infoPopupKey, "seen");
          });
        });
      }
    );
  }
  return {
    display: display,
  };
})();

/** add a global function to call from button to open the infopopup manually
 *  @function OpenInfoPopup
 *  @param    {string} itemId the  sitecore item id of the infopopup component that should be open
 */
window.OpenInfoPopup = (itemId) => {
  const popup = document.querySelector('[data-id="' + itemId + '"]');
  if (!popup) return;
  popup.removeAttribute("hidden");
  $(popup).fadeIn(() => {
    const closePopup = (event) => {
      if (
        $(event.target).closest(".info-popup").length &&
        !$(event.target).closest(".info-popup__close").length &&
        !$(event.target).closest(".closeBtn").length
      ) {
        return;
      }
      popup.removeEventListener("click", closePopup);
      $(popup).fadeOut(() => {
        popup.setAttribute("hidden", true);
      });
    }
    popup.addEventListener("click", closePopup);
  });
};

InfoPopup.display();

/* BACK-TO-TOP-BUTTON */
var BackToTopButton = (function () {
  var _scrollToTopButton;
  var _showOnDesktop;

  function update() {
    _scrollToTopButton.classList[
      (window.innerWidth < 768 || _showOnDesktop) &&
        window.pageYOffset > window.innerHeight / 1.25
        ? "add"
        : "remove"
    ]("scroll-to-top-button--visible");
  }

  function init() {
    _scrollToTopButton = document.querySelector(".scroll-to-top-button");
    _showOnDesktop = document.body.hasAttribute(
      "data-scroll-to-top-on-desktop"
    );

    _scrollToTopButton.addEventListener(
      "click",
      function () {
        $(document.documentElement).animate({ scrollTop: 0 });
      },
      { passive: true }
    );

    window.addEventListener("scroll", update, { passive: true });
    window.addEventListener("resize", update, { passive: true });
    update();
  }

  return { init: init };
})();

BackToTopButton.init();

/* MOBILE MENU EXPAND */
var MobileMenu = (function () {
  function init() {
    var visibleClass = "menu_mobile_show-submenu--submenu-visible";

    $(".menu_mobile_show-submenu").on("click", function () {
      this.classList.add(visibleClass);
    });

    $(".menu_mobile_hide-submenu").on("click", function () {
      this.parentNode.parentNode.previousElementSibling.classList.remove(
        visibleClass
      );
    });
  }

  return { init: init };
})();

MobileMenu.init();

/* LAZY-GALLERY */
var LazyGallery = (function () {
  function init() {
    $("body").on("click", ".lazy-gallery__load-more", function () {
      var root = $(this).closest(".lazy-gallery");
      loadData(root, +this.dataset.nextPage);
    });

    $("body").on("click", ".lazy-gallery__nav-item", function (event) {
      event.preventDefault();
      if (this.classList.contains("lazy-gallery__nav-item--active")) return;

      var root = $(this).closest(".lazy-gallery");
      root
        .find(".lazy-gallery__nav-item--active")
        .removeClass("lazy-gallery__nav-item--active");
      this.classList.add("lazy-gallery__nav-item--active");

      history.pushState(root.data("item-id"), document.title, this.href);
      loadData(root, 0);
    });

    window.addEventListener(
      "popstate",
      function () {
        var root = $(
          '.lazy-gallery[data-item-id="' + this.history.state + '"]'
        );
        if (!root.length) root = $(".lazy-gallery");

        var params = Utils.queryToParams(location.search);
        if (params.selectedYear) {
          var tab = root.find(
            '.lazy-gallery__nav-item[href="?selectedYear=' +
            params.selectedYear +
            '"]'
          );
          if (!tab.length) return;

          tab.siblings().removeClass("lazy-gallery__nav-item--active");
          tab.addClass("lazy-gallery__nav-item--active");
          loadData(root, 0);
        }
        if (params.selectedTag) {
          var tab = root.find(
            '.lazy-gallery__nav-item[href="?selectedTag=' +
            params.selectedTag +
            '"]'
          );
          if (!tab.length) return;

          tab.siblings().removeClass("lazy-gallery__nav-item--active");
          tab.addClass("lazy-gallery__nav-item--active");
          loadData(root, 0);
        }
      },
      { passive: true }
    );
  }

  function loadData(root, page) {
    var params = Utils.queryToParams(location.search);
    params.dsItemId = root.data("item-id");
    params.page = page;
    $.ajax({
      type: "get",
      url: "/alexiongermanyapi/LazyGallery/LazyGallery",
      data: params,
      success: function (data) {
        if (page === 0) root.find(".lazy-gallery__body").html(data);
        else root.find(".lazy-gallery__load-more-wrapper").replaceWith(data);
      },
    });
  }

  return { init: init };
})();

LazyGallery.init();

/* KEYVISUAL SLIDER */
var KeyvisualSlider = (function () {
  function init() {
    var sliderElement = document.querySelector(".keyvisual-slider");
    if (!sliderElement) return;

    var slickConfig = {
      autoplay: true,
      autoplaySpeed: 5000,
      arrows: false,
      draggable: false,
      pauseOnHover: false,
      lazyLoad: "progressive",
    };

    $(window).on("load resize orientationchange", function () {
      var initialized = sliderElement.classList.contains("slick-initialized");
      if (window.innerWidth < 768) {
        if (initialized) $(sliderElement).slick("unslick");
        return;
      }

      if (!initialized) $(sliderElement).slick(slickConfig);
    });
  }

  return { init: init };
})();

KeyvisualSlider.init();

/* FILTER TABLE */
var FilterTable = (function () {
  function init() {
    var seletcs = document.querySelectorAll(".js-filter-table-select");
    Array.prototype.forEach.call(seletcs, function (select) {
      var table = $(select).closest(".filter-table");
      NiceSelect.bind(select, {});
      select.addEventListener("change", function () {
        updateTable(table, select.value);
      });
    });

    Array.prototype.forEach.call(
      document.querySelectorAll(".filter-table"),
      function (table) {
        updateTable(table);
      }
    );
  }

  function updateTable(table, val) {
    var rows = $(table).find(".js-filter-table-row");
    var i = 0;
    rows.each(function () {
      if (!val) {
        this.style.display = "";
        setDarkClass(i, this);
        i++;
      } else {
        var filterIds = this.getAttribute("data-filter-id");
        if (filterIds.indexOf(val) < 0) {
          this.style.display = "none";
        } else {
          this.style.display = "";
          setDarkClass(i, this);
          i++;
        }
      }
    });
  }

  function setDarkClass(i, el) {
    if (i % 2 !== 0) {
      el.classList.add("dark");
    } else {
      el.classList.remove("dark");
    }
  }

  return { init: init };
})();
FilterTable.init();

function initSocialShare(button) {
  button.addEventListener("click", function (event) {
    event.preventDefault();
    button.classList.remove("leaving");
    var social = button.getAttribute("data-social");
    var url = getSocialUrl(button, social);
    social == "mail"
      ? (window.location.href = url)
      : window.open(url, social + "-share-dialog", "width=880,height=600");
  });
}

function getSocialUrl(button, social) {
  var params = getSocialParams(social);
  var newUrl = "";
  console.log(params);
  for (var i = 0; i < params.length; i++) {
    var paramValue = button.getAttribute("data-" + params[i]);
    if (params[i] == "hashtags")
      paramValue = encodeURI(paramValue.replace(/\#| /g, ""));
    if (paramValue) {
      social == "facebook"
        ? (newUrl = newUrl + "u=" + encodeURIComponent(paramValue) + "&")
        : (newUrl =
          newUrl + params[i] + "=" + encodeURIComponent(paramValue) + "&");
    }
  }
  if (social == "linkedin") newUrl = "mini=true&" + newUrl;
  return button.getAttribute("href") + "?" + newUrl;
}

function getSocialParams(social) {
  var params = [];
  switch (social) {
    case "twitter":
      params = ["text", "hashtags"];
      break;
    case "facebook":
    case "linkedin":
      params = ["url"];
      break;
    case "pinterest":
      params = ["url", "media", "description"];
      break;
    case "mail":
      params = ["subject", "body"];
      break;
  }
  return params;
}

var socialShare = document.getElementsByClassName("js-social-share");
if (socialShare.length > 0) {
  for (var i = 0; i < socialShare.length; i++) {
    (function (i) {
      initSocialShare(socialShare[i]);
    })(i);
  }
}

if (document.querySelectorAll(".lp_nav__button").length) {
  document
    .querySelector(".lp_nav__button")
    .addEventListener("click", function () {
      //toggle aria-expanded
      this.setAttribute(
        "aria-expanded",
        this.getAttribute("aria-expanded") === "true" ? "false" : "true"
      );
    });
}

const sidemenuMobile = (function () {
  "use strict";
  function init_() {
    //copy sidemenu
    const sidemenu = document.querySelector(".sidemenu");
    const sidemenuClone = sidemenu.cloneNode(true);
    sidemenuClone.classList.add("sidemenu--mobile");
    sidemenuClone.classList.remove("sidemenu");

    const mNav = document.querySelector(".menu_mobile");
    if (mNav) {
      mNav.append(sidemenuClone);
    }
  }
  return {
    init: init_,
  };
})();

if (document.querySelectorAll(".sidemenu").length) {
  const mediaQueryList = window.matchMedia("screen and (max-width : 1023px)");

  const initSidemenuMobile = function () {
    sidemenuMobile.init();
    mediaQueryList.removeEventListener("change", onMatchMedia, false);
  };

  const onMatchMedia = function (event) {
    if (event.matches) initSidemenuMobile();
  };

  mediaQueryList.addEventListener("change", onMatchMedia, false);

  if (mediaQueryList.matches) initSidemenuMobile();
}

const SUBMENUES = document.querySelectorAll(".submenu >li");
for (var i = 0; i < SUBMENUES.length; i++) {
  SUBMENUES[i].addEventListener("mouseover", function () {
    const SUBSUBMENU = this.querySelector(".submenu__sub");
    if (!SUBSUBMENU) return;
    const REQUIRED_VP =
      document.body.offsetWidth -
      Math.round(this.getBoundingClientRect().right) -
      SUBSUBMENU.offsetWidth;

    //clear direction class
    SUBSUBMENU.classList.remove("totheleft");
    //check if flyout fits into viewport, if not open to the left
    if (REQUIRED_VP < 0) {
      SUBSUBMENU.classList.add("totheleft");
    }
  });
}

(function () {
  //lazy-gallery nav as droppown
  const lazyGalleries = document.querySelectorAll(".lazy-gallery");
  if (!lazyGalleries) return;

  lazyGalleries.forEach(function (lazyGallery) {
    const buttonText = lazyGallery.querySelector(
      ".lazy-gallery__nav-item--active"
    ).innerHTML;
    let btn = document.createElement("button");
    btn.innerHTML = buttonText;
    btn.type = "button";
    btn.classList.add("lazy-gallery__nav-button");
    btn.addEventListener("click", function () {
      btn.classList.toggle("lazy-gallery__nav-button--active");
    });

    lazyGallery.prepend(btn);

    const navItems = lazyGallery.querySelectorAll(".lazy-gallery__nav-item");
    if (!navItems) return;

    navItems.forEach(function (navItem) {
      navItem.addEventListener("click", function () {
        btn.innerHTML = navItem.innerHTML;
        btn.classList.remove("lazy-gallery__nav-button--active");
      });
    });
  });
})();

(function ($) {
  // Carousel
  const carousels = $(".carousel__slides.js-carousel-slider");
  if (!carousels.length) return;

  carousels.slick({
    infinite: false,
    prevArrow:
      '<button type="button" class="slick-prev carousel__arrow carousel__arrow--prev"><span class="sr-only">Previous</span><svg viewBox="0 0 22 38" fill="none"><path d="M20 2L3 19L20 36" stroke="currentColor" stroke-width="4" stroke-linecap="round" /></svg></button>',
    nextArrow:
      '<button type="button" class="slick-next carousel__arrow carousel__arrow--next"><span class="sr-only">Next</span><svg viewBox="0 0 22 38" fill="none"><path d="M2 2L19 19L2 36" stroke="currentColor" stroke-width="4" stroke-linecap="round" /></svg></button>',
  });

  carousels.on(
    "beforeChange",
    function (_event, _slick, _currentSlide, nextSlide) {
      const carousel = this.closest(".carousel");
      if (!carousel) return;

      const prevActiveSlideNavItem = carousel.querySelector(
        `.carousel__nav-button--active`
      );
      if (prevActiveSlideNavItem) {
        prevActiveSlideNavItem.classList.remove("carousel__nav-button--active");
      }

      const nextActiveSlideNavItem = carousel.querySelector(
        `.carousel__nav-button[data-slide-index="${nextSlide}"]`
      );
      if (!nextActiveSlideNavItem) return;

      nextActiveSlideNavItem.classList.add("carousel__nav-button--active");

      const scroller = carousel.querySelector(".carousel__nav-scroller");
      if (!scroller) return;

      scroller.scrollTo({
        left:
          nextActiveSlideNavItem.offsetLeft -
          scroller.offsetWidth / 2 +
          nextActiveSlideNavItem.offsetWidth / 2,
        behavior: "smooth",
      });
    }
  );

  document.addEventListener("click", function (event) {
    const navItem = event.target.closest(
      ".carousel__nav-button:not(.carousel__nav-button--active)"
    );
    if (!navItem) return;

    const carousel = navItem.closest(".carousel");
    if (!carousel) return;

    const slides = carousel.querySelector(".carousel__slides");
    if (!slides) return;

    $(slides).slick("slickGoTo", navItem.dataset.slideIndex);
  });
})($);

(function () {
  const getScrollbarWidth = function () {
    // Create a temporary div container and append it into the body
    var container = document.createElement("div");
    // Append the container in the body
    document.body.appendChild(container);
    // Force scrollbar on the container
    container.style.overflow = "scroll";
    // Add ad fake div inside the container
    var inner = document.createElement("div");
    container.appendChild(inner);

    // Calculate the width based on the container width minus its child width
    var width = container.offsetWidth - inner.offsetWidth;
    // Remove the container from the body
    document.body.removeChild(container);
    return width;
  };

  // Get the scrollbar dimension
  var scrollbarWidth = getScrollbarWidth();
  // Set a custom property
  document.documentElement.style.setProperty(
    "--scrollbar",
    scrollbarWidth + "px"
  );
})();
